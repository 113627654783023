import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AssenzaProgrammata,
  allFieldsApprovazione,
  AssenzaProgrammataKeys,
} from "../../../models/AssenzeProgrammate";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllValidApprova as fetchAllValidToBeApproved,
  update,
  logicalDel,
  reset as resetAssenzeProgrammate
} from "../../../store/slices/assenzeProgrammateSlice";
import { fetchAllValid as fetchArticoliLegge, reset as resetArticoliLegge } from "../../../store/slices/articoliLeggeSlice";
import { fetchAllValid as fetchAssenze, reset as resetAssenze } from "../../../store/slices/assenzeSlice";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import ReadOnlyMaterialTable from "../tables/ReadOnly/ReadOnlyMaterialTable";
import { getDateYYYYMMDD } from "../../../utils/utilfunctions";
import { calculateMTableHeight } from "../../../utils/styleconst";

const ApprovazioneAssenzeProgrammateW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const title = t("plannedAbsencesApprovalTitle");

  const idTurnoAssenzaConst = "idTurnoAssenza";
  const idArticoloLeggeConst = "idArticoloLegge";

  const lookupAnagraficaSoggettoPerUnitaOperativa = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.lookup);
  const lookupTurnoAssenza = useAppSelector((state) => state.assenze.lookupAssenzaProgrammata);
  const lookupArticoloLegge = useAppSelector((state) => state.articoliLegge.lookup);

  useEffect(() => {
    dispatch(fetchAssenze());
    dispatch(fetchArticoliLegge());
  }, [dispatch]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsApprovazione);
  const [columns, setColumns] = useState<Array<Column<AssenzaProgrammata>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AssenzaProgrammata> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2) {
              resp2 = f.validate2(
                rowData[f.field as AssenzaProgrammataKeys],
                rowData[f.field2Validation as AssenzaProgrammataKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );
            }

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as AssenzaProgrammataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate) {
              return f.validate(
                rowData[f.field as AssenzaProgrammataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            return false;
          }
        }

        if (f.field === 'approvazioneAzione') {
          obj.emptyValue = undefined;
          obj.render = (rowData: AssenzaProgrammata) => {
            const approva = () => {
              const today = new Date();

              dispatch(update({
                ...rowData,
                approvazioneDateTime: getDateYYYYMMDD(today) + 'T' + today.toLocaleTimeString(),
              }));
            }

            const rifiuta = () => {
              dispatch(logicalDel([
                {
                  name: 'idAnagraficaSoggetto',
                  id: rowData.idAnagraficaSoggetto
                },
                {
                  name: 'idTurnoAssenza',
                  id: rowData.idTurnoAssenza
                },
                {
                  name: 'dataInizioAssenza',
                  id: rowData.dataInizioAssenza
                }
              ]));
            }

            return rowData.approvazioneDateTime
              ? <Typography variant='body2'>{t('approvedLabel')}</Typography>
              : <Grid container spacing={1}>
                <Grid item>
                  <Button onClick={approva} size='small' variant='outlined' color='primary'>{t('approveLabel')}</Button>
                </Grid>
                <Grid item>
                  <Button onClick={rifiuta} size='small' variant='outlined' color='secondary'>{t('refuseLabel')}</Button>
                </Grid>
              </Grid>
          }
        }

        if (f.render) obj.render = f.render

        if (!f.show) {
          obj.hidden = true;
          // obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        if (f.field === idTurnoAssenzaConst) obj.lookup = lookupTurnoAssenza;
        else if (f.field === idArticoloLeggeConst) obj.lookup = { null: t("fieldNullSelect"), ...lookupArticoloLegge };
        /*+++*/
        return obj;
      })
    );
  }, [lookupTurnoAssenza, lookupArticoloLegge, lookupAnagraficaSoggettoPerUnitaOperativa, t, allFieldsState, excludedFieldInTable, dispatch]);

  const errorBE = useAppSelector((state) => state.assenzeProgrammate.error);

  const validAssenzeProgrammate = useAppSelector(
    (state) => state.assenzeProgrammate.validAssenzeProgrammate
  );

  const statusValidAssenzeProgrammate = useAppSelector((state) => state.assenzeProgrammate.statusValidAssenzeProgrammate);

  const [data, setData] = useState<Array<AssenzaProgrammata>>([]);

  useEffect(() => {
    setData(validAssenzeProgrammate)
  }, [validAssenzeProgrammate]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      dispatch(resetAssenzeProgrammate());
      dispatch(resetArticoliLegge());
      dispatch(resetAssenze());
    };
  }, [dispatch]);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };

  return (
    <Box>
      <ReadOnlyMaterialTable
        title={title}
        columns={columns}
        columnsButton={true}
        data={data}
        fetchAllValid={fetchAllValidToBeApproved}
        statusValid={statusValidAssenzeProgrammate}
        errorBE={errorBE}
        logoUri={logoUri}
        localizedDatePicker={true}
        exportDataExtra={exportDataExtra}
        isExportLandscape={true}
        extraOptions={{
          minBodyHeight: calculateMTableHeight(0, false, false),
          maxBodyHeight: calculateMTableHeight(0, false, false),
        }}
      />
    </Box>
  );
};
export default ApprovazioneAssenzeProgrammateW;
