import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

const PageForbidden = () => {
  const { t } = useTranslation();
  return <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
  ><h1>{t('forbiddenMessage')}</h1></Grid>
}
export default PageForbidden;