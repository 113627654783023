export const sideBarWidth = 280;
export const appBarHeight = 64;

export const calculateMTableHeight = (extraOffset: number = 0, hasTab: boolean = false, hasSimpleSelect: boolean = false, hasPaging: boolean = false) => {
  const materialTableTopOffset = 180;
  const tab = hasTab ? 48 : 0;
  const simpleSelect = hasSimpleSelect ? 135 : 0;
  const paging = hasPaging ? 50 : 0;

  return window.innerHeight - (materialTableTopOffset + tab + simpleSelect + paging + extraOffset)
}