import { Fields } from "./Fields";

export const elementIdProps = [];
export const elementRenderProps = [];

export const allFields: Fields[] = [
    {
        field: "nominativo", type: 'string', titleKey: "nominativeParam", required: false, show: true,
    },
    {
        field: "qualifica", type: 'string', titleKey: "qualificationParam", required: false, show: true, defaultGroupOrder: 0
    },
    {
        field: "dataAssegnazione", type: 'date', titleKey: "currentAssignDateParam", required: false, show: true,
    },
    {
        field: "unitaOperativaNome", type: 'string', titleKey: "currentOperationalUnitParam", required: false, show: true,
    },
    {
        field: "dataAssegnazionePrecedente", type: 'date', titleKey: "previousAssignDateParam", required: false, show: true,
    },
    {
        field: "unitaOperativaPrecedente", type: 'string', titleKey: "previousOperationalUnitParam", required: false, show: true,
    },
];
