import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllDeleted, getAllValid, getById, restoreById, upd, getAllDeletedByFilter, getAllValidByFilter, getAllValidByIds, getAllDeletedByIds, getByIds } from '../../services/services.service'
import { AssenzaProgrammata, AssenzaProgrammataKeys, elementIdProps } from '../../models/AssenzeProgrammate';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "assenzeprogrammate";
const entity = "assenzeprogrammate";

interface AssenzeProgrammateState {
  statusValidAssenzeProgrammate: StatusEnum,
  validAssenzeProgrammate: AssenzaProgrammata[],
  statusDeletedAssenzeProgrammate: StatusEnum,
  deletedAssenzeProgrammate: AssenzaProgrammata[],
  assenzaProgrammata: AssenzaProgrammata | null,
  error: string | null,

  lookupAssenzeValidi: Lookup,
  lookupAssenzeEliminati: Lookup,
}

const initialState: AssenzeProgrammateState = {
  statusValidAssenzeProgrammate: StatusEnum.Succeeded,
  validAssenzeProgrammate: [],
  statusDeletedAssenzeProgrammate: StatusEnum.Succeeded,
  deletedAssenzeProgrammate: [],
  assenzaProgrammata: null,
  error: null,

  lookupAssenzeValidi: {},
  lookupAssenzeEliminati: {},
}


export const fetchByIds = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchByIds', async (obj: Record<string, string | boolean | null>) => {
  const ids: (string | boolean)[] = ['approvazionerichiesta']
  if (obj) {
    if (obj['soloDaApprovare'] !== null && obj['soloDaApprovare'] !== undefined)
      ids.push(obj['soloDaApprovare'])
    else
      ids.push(true)

    if ((obj['inizioData'] !== null && obj['inizioData'] !== undefined) || (obj['fineData'] !== null && obj['fineData'] !== undefined)) {
      if (obj['inizioData'] !== null && obj['inizioData'] !== undefined)
        ids.push(obj['inizioData'])
      else
        ids.push('1900-01-01T00:00:00')
      if (obj['fineData'] !== null && obj['fineData'] !== undefined)
        ids.push(obj['fineData'])
      else
        ids.push('9999-12-31T00:00:00')
    }


  }

  const response = await getByIds(microservice, entity, ids, ['/assenzaProgrammata/dipendenti/fetchByIds']);

  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/all/valid/soggetto/{idAnagraficaSoggetto}
export const fetchAllValidByAnagrafica = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchAllValidByAnagrafica', async (obj: { idAnagraficaSoggetto: number }) => {
  const ids = ['soggetto', obj.idAnagraficaSoggetto];

  const response = await getAllValidByIds(microservice, entity, ids, ['/assenzaProgrammata/dipendenti/fetchAllValidByAnagrafica']);
  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/all/deleted/soggetto/{idAnagraficaSoggetto}
export const fetchAllDeletedByAnagrafica = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchAllDeletedByAnagrafica', async (obj: { idAnagraficaSoggetto: number }) => {
  const ids = ['soggetto', obj.idAnagraficaSoggetto];

  const response = await getAllDeletedByIds(microservice, entity, ids, ['/assenzaProgrammata/dipendenti/fetchAllDeletedByAnagrafica']);
  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const fetchById = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as AssenzaProgrammata;
});

// cerca/assenzeprogrammate/all/valid
export const fetchAllValid = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/assenzaProgrammata/dipendenti/fetchAllValid']);

  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/all/deleted
export const fetchAllDeleted = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);

  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/all/valid/{approvata}
export const fetchAllValidApprova = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchAllValidApprova', async (approvata: boolean = false) => {
  const response = await getAllValidByIds(microservice, entity, [approvata], ['/assenzaProgrammata/dipendenti/fetchAllValidApprova']);

  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/all/deleted/{approvata}
export const fetchAllDeletedApprova = createAsyncThunk(microservice + '/dipendenti/fetchAllDeletedApprova', async (approvata: boolean = false) => {
  const response = await getAllDeletedByIds(microservice, entity, [approvata], ['/assenzaProgrammata/dipendenti/fetchAllDeletedApprova']);

  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/all/valid/unitaoperativa/{idStruttura}/{idDipartimento}/{idUnitaOperativa}
export const fetchValidByUnitaOperativa = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchValidByUnitaOperativa', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let filterArr = [obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa];
  const response = await getAllValidByFilter(microservice, entity, 'unitaoperativa', filterArr);
  return response.data as AssenzaProgrammata[];
});

// cerca/assenzeprogrammate/all/deleted/unitaoperativa/{idStruttura}/{idDipartimento}/{idUnitaOperativa}
export const fetchDeletedByUnitaOperativa = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/fetchDeletedByUnitaOperativa', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let filterArr = [obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa];
  const response = await getAllDeletedByFilter(microservice, entity, 'unitaoperativa', filterArr);
  return response.data as AssenzaProgrammata[];
});

// "cerca/assenzeprogrammate/all/valid/assenzaprogrammata/{dataInizioPeriodo}/{dataFinePeriodo}"
export const fetchAllValidByPeriodo = createAsyncThunk(entity + '/assenzaProgrammata/dipendenti/fetchAllValidByPeriodo', async (obj: { dataInizioPeriodo: string, dataFinePeriodo: string }) => {
  const params = ['assenzaprogrammata', ...Object.values(obj).filter(elem => elem != null)];

  const response = await getAllValidByIds(microservice, entity, params, ['/assenzaProgrammata/dipendenti/fetchAllValidByPeriodo']);

  return response.data as AssenzaProgrammata[];
});

// "cerca/assenzeprogrammate/all/deleted/assenzaprogrammata/{dataInizioPeriodo}/{dataFinePeriodo}"
export const fetchAllDeletedByPeriodo = createAsyncThunk(entity + '/assenzaProgrammata/dipendenti/fetchAllDeletedByPeriodo', async (obj: { dataInizioPeriodo: string, dataFinePeriodo: string }) => {
  const params = ['assenzaprogrammata', ...Object.values(obj).filter(elem => elem != null)];

  const response = await getAllDeletedByIds(microservice, entity, params, ['/assenzaProgrammata/dipendenti/fetchAllDeletedByPeriodo']);

  return response.data as AssenzaProgrammata[];
});

// inserisci/assenzeprogrammate
export const insert = createAsyncThunk(microservice + '/assenzaProgrammata/insert', async (assenzaProgrammata: AssenzaProgrammata) => {
  const response = await create(assenzaProgrammata, microservice, entity);
  return response.data as AssenzaProgrammata;
});

// modifica/assenzeprogrammate
export const update = createAsyncThunk(microservice + '/assenzaProgrammata/update', async (assenzaProgrammata: AssenzaProgrammata) => {
  const response = await upd(assenzaProgrammata, microservice, entity);
  return response.data as AssenzaProgrammata;
});

// elimina/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const logicalDel = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// restore/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const restore = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete/assenzeprogrammate/id/{idAnagraficaSoggetto}/{idTurnoAssenza}/{dataInizioAssenza}
export const physicalDel = createAsyncThunk(microservice + '/assenzaProgrammata/dipendenti/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const assenzeProgrammateSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;;
    },
    resetError: (state: AssenzeProgrammateState) => {
      state.error = initialState.error;
      state.statusValidAssenzeProgrammate = initialState.statusValidAssenzeProgrammate;
      state.statusDeletedAssenzeProgrammate = initialState.statusDeletedAssenzeProgrammate;
    }
  },
  extraReducers: builder => {


    // fetchById
    builder.addCase(fetchByIds.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchByIds.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }

      state.validAssenzeProgrammate = []
    })
    builder.addCase(fetchByIds.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.validAssenzeProgrammate = payload;

    })

    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.assenzaProgrammata = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.assenzaProgrammata = payload;
    })

    // fetchAllValidByPeriodo
    builder.addCase(fetchAllValidByPeriodo.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByPeriodo.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.validAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllValidByPeriodo.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.validAssenzeProgrammate = payload ?? [];
    })

    // fetchAllDeletedByPeriodo
    builder.addCase(fetchAllDeletedByPeriodo.pending, (state) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedByPeriodo.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAssenzeProgrammate = StatusEnum.Failed;
      }
      state.deletedAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllDeletedByPeriodo.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Succeeded;
      state.deletedAssenzeProgrammate = payload ?? [];
    })

    // fetchAllValidByAnagrafica
    builder.addCase(fetchAllValidByAnagrafica.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByAnagrafica.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.validAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllValidByAnagrafica.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.validAssenzeProgrammate = payload ?? [];
    })

    // fetchAllDeletedByAnagrafica
    builder.addCase(fetchAllDeletedByAnagrafica.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedByAnagrafica.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAssenzeProgrammate = StatusEnum.Failed;
      }
      state.deletedAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllDeletedByAnagrafica.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Succeeded;
      state.deletedAssenzeProgrammate = payload ?? [];
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.validAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.validAssenzeProgrammate = payload ?? [];
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAssenzeProgrammate = StatusEnum.Failed;
      }
      state.deletedAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Succeeded;
      state.deletedAssenzeProgrammate = payload ?? [];
    })

    // fetchAllValidApprova
    builder.addCase(fetchAllValidApprova.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidApprova.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.validAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllValidApprova.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.validAssenzeProgrammate = payload ?? [];
    })

    // fetchAllDeletedApprova
    builder.addCase(fetchAllDeletedApprova.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedApprova.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAssenzeProgrammate = StatusEnum.Failed;
      }
      state.deletedAssenzeProgrammate = [];
    })
    builder.addCase(fetchAllDeletedApprova.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Succeeded;
      state.deletedAssenzeProgrammate = payload ?? [];
    })

    // fetchValidByUnitaOperativa
    builder.addCase(fetchValidByUnitaOperativa.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchValidByUnitaOperativa.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.validAssenzeProgrammate = [];
      state.lookupAssenzeValidi = {};
    })
    builder.addCase(fetchValidByUnitaOperativa.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.validAssenzeProgrammate = payload ?? [];
      state.lookupAssenzeValidi = createLookup(payload, 'idTurnoAssenza', ['turnoAssenzaDescrizione']);
    })

    // fetchDeletedByUnitaOperativa
    builder.addCase(fetchDeletedByUnitaOperativa.pending, (state) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(fetchDeletedByUnitaOperativa.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAssenzeProgrammate = StatusEnum.Failed;
      }
      state.deletedAssenzeProgrammate = [];
      state.lookupAssenzeValidi = {};
    })
    builder.addCase(fetchDeletedByUnitaOperativa.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata[]>) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Succeeded;
      state.deletedAssenzeProgrammate = payload ?? [];
      state.lookupAssenzeEliminati = createLookup(payload, 'idTurnoAssenza', ['turnoAssenzaDescrizione']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.assenzaProgrammata = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.assenzaProgrammata = payload;
      state.validAssenzeProgrammate = state.validAssenzeProgrammate.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
      state.assenzaProgrammata = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<AssenzaProgrammata>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      state.validAssenzeProgrammate = state.validAssenzeProgrammate.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.assenzaProgrammata = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidAssenzeProgrammate = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidAssenzeProgrammate = StatusEnum.Succeeded;
      const deleted = state.validAssenzeProgrammate.find(el => payload.ids.every(idObj => el[idObj.name as AssenzaProgrammataKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedAssenzeProgrammate = state.deletedAssenzeProgrammate.concat([deleted]);
      }
      state.validAssenzeProgrammate = state.validAssenzeProgrammate.filter(el => payload.ids.some(idObj => el[idObj.name as AssenzaProgrammataKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAssenzeProgrammate = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Succeeded;
      const valid = state.deletedAssenzeProgrammate.find(el => payload.ids.every(idObj => el[idObj.name as AssenzaProgrammataKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validAssenzeProgrammate = state.validAssenzeProgrammate.concat([valid]);
      }
      state.deletedAssenzeProgrammate = state.deletedAssenzeProgrammate.filter(el => payload.ids.some(idObj => el[idObj.name as AssenzaProgrammataKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedAssenzeProgrammate = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedAssenzeProgrammate = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedAssenzeProgrammate = StatusEnum.Succeeded;
      state.deletedAssenzeProgrammate = state.deletedAssenzeProgrammate.filter(el => payload.ids.some(idObj => el[idObj.name as AssenzaProgrammataKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = assenzeProgrammateSlice.actions;
export default assenzeProgrammateSlice.reducer;