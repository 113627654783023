import { useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@material-ui/icons/Refresh";
import jsPDF from "jspdf";
import autoTable, { CellDef, RowInput } from "jspdf-autotable";
import { saveAs } from "file-saver";
import { Abilitazione } from "../../../../models/AbilitazioneEnum";
import { useAppDispatch } from "../../../../store/hooks";
import { AsyncThunk } from "@reduxjs/toolkit";
import { Ids, PDFOptions, StatusEnum } from "../../../../models/Utils";
import { Grid, Theme, Typography } from "@material-ui/core";
import { Components, MTableBodyRow, MTableCell, MTableHeader, MTableEditField, MTableToolbar } from "@material-table/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itIT from "date-fns/locale/it";
import enGB from "date-fns/locale/en-GB";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";

import { getDateDDMMYYYY } from '../../../../utils/utilfunctions';
import { PDFExtraData } from '../../../../models/Utils';
import { DimensioneFogli, ExportType } from "../../../../utils/utildata";
import { FixedProps, TCsvCallback, TPdfCallback } from "../../../../utils/data.types";
import { format } from "date-fns";

interface GeneralMessage {
  text: string;
  color: string;
}

const useCrudMaterialTable = <T extends object,>(
  abilitazione: number,
  theme: Theme,
  title: string,
  logoUri: string | null,
  elementIdProps: string[],
  elementRenderProps: string[],
  fetchAllValid:
    | AsyncThunk<T[], void, {}>
    | AsyncThunk<T[], T, {}>
    | AsyncThunk<T, T, {}>,
  columnsButton: boolean,
  statusValid: string,
  errorBE: string | null,
  insert?: AsyncThunk<T, T, {}>,
  update?: AsyncThunk<T, T, {}>,
  physicalDel?: AsyncThunk<{ ids: Ids<any>[] }, Ids<any>[], {}>,
  localizedDatePicker: boolean = true,
  nullableTextFields?: boolean,
  fixedProps?: FixedProps,
  exportDataExtra?: PDFExtraData,
  exportType?: ExportType,
  isExportLandscape: boolean = true,
  insertCallback?: () => void,
  updateCallback?: (obj: T) => void,
  detailCallback?: (obj: T) => void,
  resetErrorCallback?: () => void,
  autofetch: boolean = true,
  isLoading: boolean = false,
  pdfOptions?: PDFOptions,
  exportPDFCallback?: TPdfCallback,
  exportCSVCallback?: TCsvCallback,
) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [generalMessage, setGeneralMessage] = useState<GeneralMessage | null>(null);
  const [wrapValidResolve, setWrapValidResolve] = useState<(() => void) | null>(null);
  const [wrapValidReject, setWrapValidReject] = useState<(() => void) | null>(null);
  const [actions, setActions] = useState<any[]>([]);
  const [editable, setEditable] = useState<Object>({});
  const [components, setComponents] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<HTMLImageElement>();
  const materialTableRef = useRef();

  useEffect(() => {
    return () => {
      setErrorMessage(null);
      setWrapValidResolve(null);
      setWrapValidReject(null);
      setActions([]);
      setEditable({});
      setComponents({});
      setLoading(false);
      setLogoImage(undefined);
    };
  }, []);

  useEffect(() => {
    if (wrapValidResolve && statusValid === StatusEnum.Succeeded) {
      setErrorMessage(null);
      setTimeout(() => {
        wrapValidResolve();
      }, 1000);
      setWrapValidResolve(null);
      setLoading(false);
    } else if (wrapValidReject && statusValid === StatusEnum.Failed) {
      setErrorMessage(errorBE);
      wrapValidReject();
      setWrapValidReject(null);
      setLoading(false);
    }
  }, [wrapValidResolve, statusValid, dispatch, errorBE, wrapValidReject]);

  useEffect(() => {
    const im = new Image();
    im.onload = () => {
      setLogoImage(im);
    };
    if (logoUri)
      im.src = logoUri.startsWith('data:') ? logoUri : 'data:image/png;base64,' + logoUri;
  }, [logoUri]);

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading]);

  useEffect(() => {
    const refreshData = (resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      if (autofetch)
        if (fixedProps) {
          let fav = fetchAllValid as AsyncThunk<T[], T, {}>;
          dispatch(fav(fixedProps as T));
        } else {
          let fav = fetchAllValid as AsyncThunk<T[], void, {}>;
          dispatch(fav());
        }
    };
    setLoading(true);
    new Promise((resolve, reject) => {
      refreshData(resolve, reject);
    });
  }, [abilitazione, fetchAllValid, fixedProps, dispatch, t, autofetch]);

  const localization = useMemo(() => {
    return {
      body: {
        emptyDataSourceMessage: t("emptyDataSourceMessage"),
        addTooltip: t("addTooltip"),
        deleteTooltip: t("deleteTooltip"),
        editTooltip: t("editTooltip"),
        filterRow: {
          filterTooltip: t("filterTooltip"),
        },
        editRow: {
          deleteText: t("rowDefDeleteText"),
          cancelTooltip: t("cancelTooltip"),
          saveTooltip: t("saveTooltip"),
        },
      },
      grouping: {
        placeholder: t("groupingPlaceholder"),
        groupedBy: t("groupedBy"),
      },
      header: {
        actions: t("actions"),
      },
      pagination: {
        labelDisplayedRows: t("labelDisplayedRows"),
        labelRowsSelect: t("labelRowsSelect"),
        labelRowsPerPage: t("labelRowsPerPage"),
        firstPageLabel: t("firstPageLabel"),
        firstTooltip: t("firstTooltip"),
        previousPageLabel: t("previousPageLabel"),
        previousTooltip: t("previousTooltip"),
        nextPageLabel: t("nextPageLabel"),
        nextTooltip: t("nextTooltip"),
        lastPageLabel: t("lastPageLabel"),
        lastTooltip: t("lastTooltip"),
      },
      toolbar: {
        addRemoveColumns: t("addRemoveColumns"),
        nRowsSelected: t("nRowsSelected"),
        showColumnsTitle: t("showColumnsTitle"),
        showColumnsAriaLabel: t("showColumnsAriaLabel"),
        exportTitle: t("exportTitle"),
        exportAriaLabel: t("exportAriaLabel"),
        exportName: t("exportName"),
        searchTooltip: t("searchTooltip"),
        searchPlaceholder: t("searchPlaceholder"),
      },
    };
  }, [t]);

  const resetErrorStatus = useCallback(() => {
    resetErrorCallback && resetErrorCallback();
    setGeneralMessage(null);
    setWrapValidResolve(() => () => { });
    setWrapValidReject(() => () => { });
  }, [resetErrorCallback])

  const operationCancel = useCallback(() => {
    setGeneralMessage({
      text: t('cancelOpLabel'),
      color: 'green',
    });
    setTimeout(() => {
      setGeneralMessage(null);
    }, 5000);
  }, [t]);

  useEffect(() => {
    const refreshData = (resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      if (fixedProps) {
        let fav = fetchAllValid as AsyncThunk<T[], T, {}>;
        dispatch(fav(fixedProps as T));
      } else {
        let fav = fetchAllValid as AsyncThunk<T[], void, {}>;
        dispatch(fav());
      }
    };

    const insertData = (obj: Object, resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      let dispObj: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(obj)) {
        let val = value;
        if (typeof value === "string") val = val.trim();
        if (val !== null && val !== undefined && val !== "") dispObj[key] = val;
      }
      if (fixedProps) {
        for (const [key, value] of Object.entries(fixedProps)) {
          dispObj[key] = value;
        }
      }
      insert && dispatch(insert(dispObj as T));
    };

    const updateData = (obj: Object, resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      let dispObj: Record<string, unknown> = {};
      for (const [key, value] of Object.entries(obj)) {
        let val = value;
        if (typeof value === "string") val = val.trim();
        if (val !== null && val !== undefined && val !== "") dispObj[key] = val;
      }
      if (fixedProps) {
        for (const [key, value] of Object.entries(fixedProps)) {
          dispObj[key] = value;
        }
      }
      update && dispatch(update(dispObj as T));
    };

    const physicalDeleteData = (ids: Ids<string>[], resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      physicalDel && dispatch(physicalDel(ids));
    };

    let act = [];
    act.push({
      icon: RefreshIcon,
      isFreeAction: true,
      tooltip: t("refreshData"),
      onClick: () => {
        setLoading(true);
        new Promise((resolve, reject) => {
          refreshData(resolve, reject);
        });
      },
    });

    if (abilitazione >= Abilitazione.READ_UPDATE) {
      if (insertCallback) {
        act.push({
          icon: AddIcon,
          isFreeAction: true,
          tooltip: t("addTooltip"),
          onClick: () => {
            resetErrorStatus();
            insertCallback();
          },
        });
      }
      if (updateCallback) {
        act.push({
          icon: EditIcon,
          tooltip: t("editAndDetailTooltip"),
          onClick: (_: unknown, rowData: T) => {
            resetErrorStatus();
            updateCallback(rowData);
          },
        });
      }
    } else if (abilitazione >= Abilitazione.READ) {
      if (detailCallback) {
        act.push({
          icon: DescriptionIcon,
          tooltip: t("detailTooltip"),
          onClick: (_: unknown, rowData: T) => {
            resetErrorStatus()
            detailCallback(rowData);
          },
        });
      }
    }
    setActions(act);

    let edit: Record<string, any> = {};
    if (abilitazione >= Abilitazione.READ_UPDATE) {
      if (!insertCallback && insert) {
        edit["onRowAdd"] = (newData: T) => {
          return new Promise((resolve, reject) => {
            insertData(newData, resolve, reject);
          });
        }
        edit["onRowAddCancelled"] = () => {
          resetErrorStatus();
          operationCancel();
        };
      }
      if (!updateCallback && update) {
        edit["onRowUpdate"] = (newData: object) => {
          return new Promise((resolve, reject) => {
            updateData(newData, resolve, reject);
          });
        };
        edit["onRowUpdateCancelled"] = () => {
          resetErrorStatus();
          operationCancel();
        };
      }
    }
    if (abilitazione >= Abilitazione.READ_UPDATE_LOGICDELETE_DEFDELETE_RESTORE && physicalDel) {
      edit["onRowDelete"] = (oldData: Record<string, string>) =>
        new Promise((resolve, reject) => {
          const ids: Ids<string>[] = [];
          elementIdProps.forEach((propId) =>
            ids.push({ name: propId, id: oldData[propId] })
          );
          physicalDeleteData(ids, resolve, reject);
        });
    }
    setEditable(edit);
  }, [abilitazione, elementIdProps, elementRenderProps, t, dispatch, insert, update, physicalDel, fetchAllValid, fixedProps, insertCallback, updateCallback, detailCallback, resetErrorStatus, operationCancel]);

  useEffect(() => {
    const comp: Components = {};

    comp["Header"] = (props) => {
      const _props = {
        ...props,
        headerStyle: {
          position: 'sticky',
          top: 0,
          zIndex: 10
        },
      }
      return <MTableHeader
        {..._props}
      />
    }

    comp["Cell"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        const formattedDate = props.value ? getDateDDMMYYYY(new Date(props.value)) : '';
        const updatedProps = { ...props, "value": formattedDate }
        return <MTableCell {...updatedProps} />
      }
      else return <MTableCell {...props} />
    }

    comp["Toolbar"] = (props) => {
      const INSERT_ICON = 1;
      if ((insertCallback || insert) && props.actions[INSERT_ICON]) {
        const preInsertCallback = props.actions[INSERT_ICON].onClick;

        props.actions[INSERT_ICON].onClick = () => {
          resetErrorStatus();
          preInsertCallback();
        }
      }

      console.log({ props })

      return <>
        <MTableToolbar {...props} />
        {errorMessage && errorMessage.length > 0 && (
          <Grid container justifyContent="center">
            <Typography color="secondary" variant="body1">
              {errorMessage}
            </Typography>
          </Grid>
        )}
        {generalMessage && generalMessage.text && generalMessage.color && generalMessage.text.length > 0 && (
          <Grid container justifyContent="center">
            <Typography style={{ color: generalMessage.color }} variant="body1">
              {generalMessage.text}
            </Typography>
          </Grid>
        )}
      </>
    };

    comp["EditField"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={i18n.language === "it-IT" || i18n.language === "it" ? itIT : enGB}
          >
            <KeyboardDatePicker
              okLabel={t('insertLabel')}
              clearLabel={t("clearLabel")}
              cancelLabel={t("cancelLabel")}
              clearable
              margin="none"
              format="dd/MM/yyyy"
              value={props.value ? props.value : null}
              error={props.error}
              helperText={props.helperText}
              onChange={(d) => {
                if (d) {
                  let month = (d.getMonth() + 1).toString().padStart(2, '0');
                  let day = d.getDate().toString().padStart(2, '0');
                  props.onChange(
                    d.getFullYear() + "-" + month + "-" + day + "T00:00:00"
                  );
                } else props.onChange(null);
              }}
              KeyboardButtonProps={{ "aria-label": "change date" }}
            />
          </MuiPickersUtilsProvider>
        );
      } else if (
        nullableTextFields &&
        props.columnDef.type === "string" &&
        (props.value === null || props.value === undefined)
      ) {
        let editProps = { ...props, value: "" };
        return <MTableEditField {...editProps} />;
      } else return <MTableEditField {...props} />;
    };

    comp['Row'] = (props) => {
      const MODIFY_ICON = 2 - (insertCallback || insert ? 0 : 1);
      const DELETE_ICON = 3 - (insertCallback || insert ? 0 : 1);

      if (props.actions[MODIFY_ICON] && props.actions[DELETE_ICON]) {
        // Prendere l'oggetto per update e delete
        const updateButton: Record<string, (e: unknown, rowData: unknown) => void> = updateCallback ? props.actions[MODIFY_ICON] : props.actions[MODIFY_ICON]();
        const deleteButton: Record<string, (e: unknown, rowData: unknown) => void> = props.actions[DELETE_ICON]();

        // Prendere l'evento onClick callback di ogni buttone
        const preUpdateCallback: (e: unknown, rowData: unknown) => void = updateButton['onClick'];
        const preDeleteCallback: (e: unknown, rowData: unknown) => void = deleteButton['onClick'];

        // La nuova callback contiene il reset dello stato nello store e la callback originale
        updateButton['onClick'] = (e: unknown, rowData: unknown) => {
          resetErrorStatus();
          preUpdateCallback(e, rowData);
        }

        deleteButton['onClick'] = (e: unknown, rowData: unknown) => {
          resetErrorStatus();
          preDeleteCallback(e, rowData);
        }

        // Reimpostare le action
        props.actions[MODIFY_ICON] = updateCallback ? updateButton : () => updateButton;
        props.actions[DELETE_ICON] = () => deleteButton;
      }

      return <MTableBodyRow {...props} />
    }

    setComponents(comp);
  }, [dispatch, errorMessage, generalMessage, i18n.language, insert, insertCallback, localizedDatePicker, nullableTextFields, resetErrorStatus, t, theme.palette.secondary.main, updateCallback]);

  const exportMenuOptions = useMemo(() => [
    {
      label: t("exportPDF"),
      exportFunc: (cols: Record<string, any>[], data: Record<string, any>[], tableData: Record<string, object[]>) => {
        const MARGIN_H = 5;
        const PAPER_FORMAT = pdfOptions?.format ?? 'a4';
        const PAPER_ORIENTATION = isExportLandscape ? "landscape" : "portrait";

        /**
         * Add pages count and generation timestamp
         */
        const addFooter = (doc: jsPDF) => {
          const pageCount = doc.internal.pages.length - 1; // index 0 is empty
          const timestamp = new Date();

          for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.setTextColor(64);

            doc.text(
              format(timestamp, 'dd/MM/yyyy HH:mm:ss'),
              DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width - MARGIN_H,
              DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].height - MARGIN_H,
              {
                align: 'right',
              }
            );
            doc.text(
              i + ' / ' + pageCount,
              DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width / 2,
              DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].height - MARGIN_H,
              {
                align: 'center',
              }
            );
          }
        }

        if (exportPDFCallback) {
          const extra = {
            logoImage,
            title,
            t,
            theme,
            exportDataExtra,
            fixedProps: fixedProps ?? {}
          }

          const _pdfOptions: PDFOptions = {
            ...pdfOptions,
            format: PAPER_FORMAT,
            orientation: PAPER_ORIENTATION
          };

          const pdfDoc = exportPDFCallback(
            cols,
            data,
            tableData,
            extra,
            _pdfOptions,
          );

          addFooter(pdfDoc);

          pdfDoc.output('dataurlnewwindow', { filename: title + ".pdf" });
          return;
        }

        let header: Record<string, unknown>[] = [];
        let modData: RowInput[] = [];

        if (tableData.groupedData.length > 0) {

          let newCols = cols.filter((elem: Record<string, any>) => {
            return [undefined, null].includes(elem.defaultGroupOrder as any)
          })

          header = newCols.map((colonna: Record<string, any>) => {
            return {
              ...colonna,
              cellWidth: { colonna: colonna.width ? (colonna.width as number) / 5 : 'auto' },
              styles: { fillColor: theme.palette.primary.main }
            };
          });

          (tableData.groupedData as Record<string, any>[]).map((gruppo) => {

            let retval = gruppo['data'].map((riga: Record<string, unknown>) => {
              return header.map(({ field }, index) => {
                const pattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$/;    // yyyy-mm-ddT##:##:##
                const val = riga[field as string] as string;
                let cellDef: CellDef = {};

                if (pattern.test(val)) {
                  cellDef.content = getDateDDMMYYYY(new Date(val));
                } else if (typeof val === "boolean") {
                  cellDef.content = val ? t("yes") : t("no");
                } else cellDef.content = val;

                if (cellDef.content == null) {
                  cellDef.content = "";
                }
                if (newCols[index] && newCols[index].cellStyle && newCols[index].cellStyle['backgroundColor']) {
                  cellDef.styles = { fillColor: newCols[index].cellStyle['backgroundColor'] };
                }

                return cellDef;
              }) as RowInput
            })

            return [
              [
                {
                  content: gruppo['value'],
                  styles: {
                    halign: 'left',
                    fontStyle: 'bold'
                  },
                  colSpan: header.length
                }
              ],
              ...retval
            ];

          })
            .forEach(elem => {
              modData.push(...elem)
            })

        } else {  // se non ci sono gruppi
          header = cols.map(colonna => {
            return {
              ...colonna,
              cellWidth: { colonna: colonna.width ? colonna.width / 5 : 'auto' },
              styles: { fillColor: theme.palette.primary.main }
            };
          });

          modData = data.map((riga) => {
            return Object.values(riga).map((value: any, index) => {

              const pattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$/;    // yyyy-mm-ddT##:##:##
              let cellDef: CellDef = {};

              if (pattern.test(value)) {
                cellDef.content = getDateDDMMYYYY(new Date(value));
              } else if (typeof value === "boolean") {
                cellDef.content = value ? t("yes") : t("no");
              } else cellDef.content = value;

              if (cellDef.content == null) {
                cellDef.content = "";
              }

              if (cols[index] && cols[index].cellStyle && cols[index].cellStyle['backgroundColor']) {
                cellDef.styles = { fillColor: cols[index].cellStyle['backgroundColor'] };
              }
              return cellDef;
            }) as RowInput;
          });
        }

        const imgHeight = 12;
        const Y_OFFSET = 5;

        const reworkData = (data: RowInput[], headerLength: number) => {
          return data.length > 0
            ? data
            : [[{
              content: t('noDataPresentLabel'),
              styles: {
                halign: 'center',
                fontStyle: 'italic'
              } as CellDef,
              colSpan: headerLength
            }]] as RowInput[];
        }

        const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, });
        if (logoImage) {
          const imgWidth = (logoImage.width * imgHeight) / logoImage.height;
          doc.addImage(logoImage, "PNG", MARGIN_H, Y_OFFSET, imgWidth, imgHeight);
        }
        doc.text(
          title,
          DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width / 2,
          imgHeight + 8 + Y_OFFSET,
          {
            align: 'center'
          }
        );

        // fixedProps header table
        if (exportDataExtra && exportDataExtra.head)
          autoTable(doc, {
            startY: imgHeight + 12 + Y_OFFSET,
            margin: {
              horizontal: MARGIN_H,
            },
            head: [exportDataExtra.head.title],
            body: [exportDataExtra.head.value],
            headStyles: {
              fillColor: theme.palette.secondary.main,
            }
          });

        /*
         * DATI
         */
        autoTable(doc, {
          startY: exportDataExtra ? undefined : imgHeight + 12 + Y_OFFSET,
          margin: {
            horizontal: MARGIN_H,
          },
          head: [header],
          body: reworkData(modData, header.length),
          headStyles: {
            fillColor: theme.palette.primary.main,
            halign: 'center',
            cellPadding: 2,
            valign: 'middle'
          },
          theme: "grid",
          styles: {
            fontSize: pdfOptions && pdfOptions.bodyFontSize ? pdfOptions.bodyFontSize : 10,
            cellPadding: 2,
          },
        });

        if (exportDataExtra && exportDataExtra.extra) {
          exportDataExtra.extra.forEach(extra =>
            autoTable(doc, {
              head: [extra.title],
              body: reworkData(extra.value, extra.title.length),
              headStyles: {
                fillColor: theme.palette.primary.main,
              }
            })
          )
        }

        addFooter(doc);

        doc.output('dataurlnewwindow', { filename: title + ".pdf" });
      },
    },
    {
      label: t("exportCSV"),
      exportFunc: (cols, data: Record<string, any>[], tableData: Record<string, object[]>) => {
        const sep = '|'
        let csvData = "sep=" + sep + "\n";

        if (exportCSVCallback) {
          csvData = exportCSVCallback(cols, data, tableData, csvData, sep);
        } else {
          // headers
          cols.forEach((col, ind) => {
            csvData += col.title;
            if (ind !== cols.length - 1) csvData += sep;
            else csvData += "\n";
          });
          // data
          data?.forEach((row, ind) => {
            const values = Object.values(row);
            values?.forEach((el, ind) => {
              csvData += el;
              if (ind !== cols.length - 1) csvData += sep;
            });
            if (ind !== data.length - 1) csvData += "\n";
          });
        }

        var myFile = new File([csvData], title + ".csv", {
          type: "text/csv;charset=utf-8",
        });
        saveAs(myFile);
      },
    },
  ], [exportCSVCallback, exportDataExtra, exportPDFCallback, fixedProps, isExportLandscape, logoImage, pdfOptions, t, theme, title]);

  const options = useMemo(() => {
    return {
      toolbarButtonAlignment: "left" as "left",
      addRowPosition: 'first' as 'first',
      columnsButton: columnsButton,
      exportAllData: true,
      thirdSortClick: false,
      exportMenu: exportType === ExportType.PDF || exportType === ExportType.CSV
        ? [exportMenuOptions[exportType]]
        : exportMenuOptions,
    };
  }, [columnsButton, exportMenuOptions, exportType]);

  return {
    loading,
    materialTableRef,
    localization,
    editable,
    actions,
    options,
    components,
  };
};
export default useCrudMaterialTable;
