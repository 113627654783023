import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath, } from "../../../utils/utilconst";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  insert,
  physicalDel,
  update,
  resetError as resetErrorAnagraficaSoggettiSchedaProfessionale
} from "../../../store/slices/anagraficaSoggettiSchedaProfessionaleSlice";
import { elementIdProps, elementRenderProps, AnagraficaSoggettiSchedaProfessionale, allFields, AnagraficaSoggettiSchedaProfessionaleKeys } from '../../../models/AnagraficaSoggettiSchedeProfessionali';
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { componentTabsPath, schedaProfessionaleTabPath, componentInsertPath } from "../../../utils/innerFuncPaths";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { Lookup, PDFExtraData } from "../../../models/Utils";
import i18n from "../../../i18n";
import { Fields } from "../../../models/Fields";
import { Box, Button, Paper } from "@material-ui/core";
import { fetchAllValid as fetchAllValidTipoIncarico } from "../../../store/slices/TipoIncaricoSlice";
import InnerComponentViewsAnagrafiche from "./AnagraficaSoggettiComponents/InnerComponentsViewsAnagrafiche";
import { addMonths } from "date-fns/esm";
import { getDateYYYYMMDD_BackEnd } from "../../../utils/utilfunctions";
import useInitValid from "./hooks/useInitValid";
import _ from 'lodash';

interface AnagraficaSoggettiSchedaProfessionaleWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiSchedaProfessionale: AnagraficaSoggettiSchedaProfessionale[],
}

const AnagraficaSoggettiSchedaProfessionaleW = ({ idAnagraficaSoggetto, anagraficaSoggettiSchedaProfessionale }: AnagraficaSoggettiSchedaProfessionaleWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiSchedaProfessionale.error);
  const errorBEAnagrafica = useAppSelector(state => state.anagraficaSoggetti.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiSchedaProfessionale());
  }, [dispatch]);

  const articoloLeggeLookup = useAppSelector(state => state.articoliLegge.lookupExtended);
  const motivoLookup = useAppSelector(state => state.motivi.lookup)
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);
  const lookupTipoIncaricoDescrizione = useAppSelector(state => state.tipoIncarico.lookupDescrizione);
  const tipoContrattoLookup = useAppSelector(state => state.lookup.tipoContrattoLookup);
  const _tipoPartTimeLookup = useAppSelector(state => state.lookup.tipoPartTimeLookup);

  const [obj, setObj] = useState<AnagraficaSoggettiSchedaProfessionale | null>(null);
  const anagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.anagraficaSoggettoEstesa);

  const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false);

  const idAnagSoggConst = "idAnagraficaSoggetto";
  const idArticoloLegge = "idArticoloLeggeLegge15101";
  const idMotivo = "idMotivo";
  const tipoPartTime = 'partTimeTipo';
  const idTipoIncarico = "idTipoIncarico";
  const tipoContratto = "tipoContratto";

  useEffect(() => {
    dispatch(fetchAllValidTipoIncarico());
  }, [dispatch]);

  const tipoPartTimeLookup: Lookup = useMemo(() => {
    const temp: Lookup = {};
    Object.entries(_tipoPartTimeLookup).forEach(([key, value]) => {
      temp[key] = t(value);
    });

    return temp;
  }, [_tipoPartTimeLookup, t]);

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiSchedaProfessionale>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiSchedaProfessionale> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2) {
              resp2 = f.validate2(
                rowData[f.field as AnagraficaSoggettiSchedaProfessionaleKeys],
                rowData[f.field2Validation as AnagraficaSoggettiSchedaProfessionaleKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );
            }

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as AnagraficaSoggettiSchedaProfessionaleKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate) {
              return f.validate(
                rowData[f.field as AnagraficaSoggettiSchedaProfessionaleKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }

            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        // override default values
        if (f.type === 'boolean')
          obj.emptyValue = undefined;

        /*+++*/
        if (f.lookupField)
          switch (f.field) {
            case idArticoloLegge:
              obj.lookup = articoloLeggeLookup;
              break;
            case idMotivo:
              obj.lookup = motivoLookup;
              break;
            case tipoPartTime:
              obj.lookup = tipoPartTimeLookup;
              break;
            case idTipoIncarico:
              obj.lookup = lookupTipoIncaricoDescrizione;
              break;
            case tipoContratto:
              obj.lookup = tipoContrattoLookup;
          }
        /*+++*/
        return obj;
      }));
  }, [allFieldsState, articoloLeggeLookup, motivoLookup, t, tipoPartTimeLookup, lookupTipoIncaricoDescrizione, tipoContrattoLookup]);

  const formLookup = useMemo(() => {
    return {
      [idMotivo]: motivoLookup,
      [idArticoloLegge]: articoloLeggeLookup,
      [tipoPartTime]: tipoPartTimeLookup,
      [idTipoIncarico]: lookupTipoIncaricoDescrizione,
      tipoContratto: tipoContrattoLookup,

    }
  }, [motivoLookup, articoloLeggeLookup, tipoPartTimeLookup, lookupTipoIncaricoDescrizione, tipoContrattoLookup]);

  const statusValidAnagraficaSoggettiSchedaProfessionale = useAppSelector(state => state.anagraficaSoggettiSchedaProfessionale.statusValidAnagraficaSoggettiSchedeProfessionali);
  const statusAnagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.statusAnagraficaSoggettoEstesa);

  const [data, setData] = useState<Array<AnagraficaSoggettiSchedaProfessionale>>([]);

  useEffect(() => {
    const AS_SchedaProfessionale = anagraficaSoggettiEstesa?.anagraficaSoggettiSchedeProfessionali;
    setObj(state => {
      if (AS_SchedaProfessionale && AS_SchedaProfessionale.length > 0)
        return AS_SchedaProfessionale?.reduce((oldData, newData) => {
          return new Date(oldData.dataInizioValidita) > new Date(newData.dataInizioValidita) ? oldData : newData;
        });
      return state;
    })
  }, [anagraficaSoggettiEstesa?.anagraficaSoggettiSchedeProfessionali, anagraficaSoggettiSchedaProfessionale]);

  useEffect(() => {
    if (anagraficaSoggettiEstesa?.anagraficaSoggettiSchedeProfessionali)
      setData(anagraficaSoggettiEstesa.anagraficaSoggettiSchedeProfessionali);
  }, [anagraficaSoggettiEstesa?.anagraficaSoggettiSchedeProfessionali]);

  useInitValid(
    statusValidAnagraficaSoggettiSchedaProfessionale,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );

  useEffect(() => {
    dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  }, [dispatch, idAnagraficaSoggetto]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setObj(null);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const insertCallback = () => {
    const temp: Required<AnagraficaSoggettiSchedaProfessionale> = {
      idAnagraficaSoggetto: 0,
      dataInizioValidita: "",
      tipoContratto: "",
      matricola: "",
      dataAssunzione: "",
      dataFinePeriodoProva: "",
      dataAnzianitaServizio: "",
      dataPresaServizio: "",
      dataFineContratto: "",
      idMotivo: 0,
      dataUscita: "",
      legge15101DataInizio: "",
      idArticoloLeggeLegge15101: 0,
      articoloLeggeLegge15101Descrizione: "",
      partTime: 0,
      partTimeDataInizio: "",
      partTimeTipo: "",
      partTimeOrizzontale: "",
      partTimeVerticale: "",
      partTimeMotivazione: "",
      Legge104Soggetto1DataInizio: "",
      Legge104Soggetto1DataFine: "",
      Legge104Soggetto1SeStesso: false,
      Legge104Soggetto2DataInizio: "",
      Legge104Soggetto2DataFine: "",
      Legge104Soggetto2SeStesso: false,
      Legge104Soggetto3DataInizio: "",
      Legge104Soggetto3DataFine: "",
      Legge104Soggetto3SeStesso: false,
      specializzazione: "",
      dataInizioIncarico: "",
      dataFineIncarico: "",
      orarioSettimanale: 0,
      idTipoIncarico: 0,
      version: 0
    }

    let lastAnagraficaSoggettiSchedaProfessionale: Partial<AnagraficaSoggettiSchedaProfessionale> = {
      ..._.pick(anagraficaSoggettiEstesa, Object.keys(temp)),
      dataInizioValidita: undefined,
      orarioSettimanale: 36,
    }

    if (lastAnagraficaSoggettiSchedaProfessionale.dataFineIncarico) {
      lastAnagraficaSoggettiSchedaProfessionale = {
        ...lastAnagraficaSoggettiSchedaProfessionale,
        tipoContratto: undefined,
        dataAssunzione: undefined,
        dataPresaServizio: undefined,
        dataAnzianitaServizio: undefined,
        specializzazione: undefined,
        dataInizioIncarico: undefined,
        dataFineIncarico: undefined,
        dataFinePeriodoProva: undefined,
        idMotivo: undefined
      }
    }

    setObj(lastAnagraficaSoggettiSchedaProfessionale as AnagraficaSoggettiSchedaProfessionale);
    history.push(anagrafichePath + componentTabsPath + schedaProfessionaleTabPath + componentInsertPath)
  };

  const updateDetailCallback = (anagraficaSoggettoSchedaProfessionale: AnagraficaSoggettiSchedaProfessionale) => {
    setObj(anagraficaSoggettoSchedaProfessionale);
    history.push(anagrafichePath + componentTabsPath + schedaProfessionaleTabPath + componentTabsPath);
  };

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idAnagraficaSoggetto':
        if (exportDataExtra.head)
          exportDataExtra.head['Anagrafica Soggetto'] = lookupAnagraficaSoggetto[fixedProps[elem]];
        break;
    }
  });

  const formCallback = useCallback((schedaProfessionale: AnagraficaSoggettiSchedaProfessionale, field: string, optionalArgs: OptionalArgs<AnagraficaSoggettiSchedaProfessionale>) => {
    const { setInternalObj } = optionalArgs;

    setInternalObj(state => {
      if (field === 'dataAssunzione') {
        if (schedaProfessionale.dataAssunzione) {
          return {
            ...state,
            dataFinePeriodoProva: getDateYYYYMMDD_BackEnd(addMonths(new Date(schedaProfessionale.dataAssunzione), 6))
          }
        }
      }

      if (field === 'partTime' || field === 'orarioSettimanale') {
        if (schedaProfessionale.orarioSettimanale != null && schedaProfessionale.partTime != null) {
          return {
            ...state,
            oreSettimanalePercentuale: (schedaProfessionale.orarioSettimanale * schedaProfessionale.partTime / 100).toFixed(1) + '0'
          }
        }
      }
      return state
    });
  }, []);

  return <Switch>
    <Route path={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath + componentInsertPath} exact>
      <InnerComponentViewsAnagrafiche
        abilitazione={abilitazione}
        mainUri={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath}
        tabsUri={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath + componentInsertPath}
        tabsView={false}
        buttonTitle={t("cancelLabel")}
        info1={t("update")}
        tabs={[
          {
            label: t("registryTitle"),
            tabPath: "",
            abilitazione: Abilitazione.READ_UPDATE,
            componentIf: (
              <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath}
                action={insert}
                fixedProps={fixedProps}
                status={statusValidAnagraficaSoggettiSchedaProfessionale}
                error={errorBE}
                update={false}
                fields={allFields}
                translate={t}
                lookups={formLookup}
                checksCallback={formCallback}
                obj={obj}
                buttonLabel="saveLabel"
              />
            ),
            componentElse: <Redirect to={anagrafichePath} />,
          },
        ]}
      />
    </Route>
    <Route path={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath + componentTabsPath} exact>
      <InnerComponentViews
        abilitazione={abilitazione}
        mainUri={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath}
        tabsUri={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath + componentTabsPath}
        tabsView={false}
        buttonTitle={t("cancelLabel")}
        info1={""}
        tabs={[
          {
            label: t("registryTitle"),
            tabPath: "",
            abilitazione: Abilitazione.READ_UPDATE,
            componentIf: (
              <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath}
                action={update}
                fixedProps={fixedProps}
                status={statusValidAnagraficaSoggettiSchedaProfessionale}
                error={errorBE}
                update={true}
                fields={allFields}
                translate={t}
                obj={obj}
                lookups={formLookup}
                checksCallback={formCallback}
                buttonLabel="saveLabel"
              />
            ),
            componentElse: <Redirect to={anagrafichePath} />,
          },
        ]}
      />
    </Route>
    <Route path={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath} exact>
      <Box flex={1} display='flex' justifyContent='center'>
      </Box>
      <Box mt={6} display="flex" justifyContent='right'>
        <Button
          size="large"
          variant="contained"
          onClick={() => insertCallback()}
        >
          {t("update")}
        </Button>
      </Box>
      <GeneralForm
        readOnly={true}
        language={i18n.language}
        componentPath={anagrafichePath + componentTabsPath + schedaProfessionaleTabPath}
        action={update}
        fixedProps={fixedProps}
        status={statusValidAnagraficaSoggettiSchedaProfessionale}
        error={errorBE}
        update={false}
        fields={allFields}
        translate={t}
        obj={anagraficaSoggettiEstesa}
        lookups={formLookup}
      />
      <Box mt={6} display="flex">
        <Box flex={1} display='flex' justifyContent='right'>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setIsArchiveOpen(state => !state);
            }}
          >
            {t("archive")}
          </Button>
        </Box>
      </Box>
      {
        isArchiveOpen &&
        <>
          <Box mt={6}>
            <Paper >
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={t('professionalCardArchiveTitle')}
                columns={columns}
                columnsButton={true}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchExtendedByIdAndDate}
                physicalDel={physicalDel}
                statusValid={statusAnagraficaSoggettiEstesa}
                errorBE={errorBEAnagrafica}
                fixedProps={fixedProps}
                exportDataExtra={exportDataExtra}
                isExportLandscape={true}
                logoUri={logoUri}
                nullableTextFields={true}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                localizedDatePicker={true}
                resetErrorCallback={resetError}
                autofetch={false}
                extraOptions={{
                  maxBodyHeight: 460,
                }}
              />
            </Paper>
          </Box>
        </>
      }
    </Route>
  </Switch>
}
export default AnagraficaSoggettiSchedaProfessionaleW;