import { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { allFields, Straordinari } from "../../../models/VerificaStraordinari";
import { AnagraficaSoggettoCurrentData } from "../../../models/AnagraficaSoggetti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import Paper from "@material-ui/core/Paper";
import { Box, Grid, Typography } from "@material-ui/core";
import { lookup as fetchStruttureLookup, reset as resetStrutture } from "../../../store/slices/struttureSlice";
import { lookupFiltered as fetchDipartimentiLookup, cleanLookup as resetDipartimentiLookup, reset as resetDipartimentiFiltered } from "../../../store/slices/dipartimentiFilteredSlice";
import { lookupFiltered as fetchUnitaOperativeLookup, cleanLookup as resetUOLookup, reset as resetUnitaOperativeFiltered } from "../../../store/slices/unitaOperativeFilteredSlice";
import { fetchStraordinariBetweenDates, reset as resetPresenze } from '../../../store/slices/presenzeSlice';

import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { componentTabsPath } from "../../../utils/innerFuncPaths";
import { Fields } from "../../../models/Fields";
import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable';
import { verificaStraordinariPath, notFoundPath } from "../../../utils/utilconst";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { PDFExtraData } from "../../../models/Utils";
import { daysInMonth, getDateDDMMYYYY } from "../../../utils/utilfunctions";
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { calculateMTableHeight } from "../../../utils/styleconst";

function isUrlOnUpdate(url: string) {
  return url === verificaStraordinariPath + componentTabsPath;
}

const VerificaStraordinariW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);
  const unitOpSelectName = useMemo(() => t("operatingUnitTitle"), [t]);
  const unitOpSelectLabel = useMemo(() => t("operatingUnitSelect"), [t]);
  const fromSelectName = useMemo(() => t("startDateTitle"), [t]);
  const fromSelectLabel = useMemo(() => t("startDateSelect"), [t]);
  const toSelectName = useMemo(() => t("endDateTitle"), [t]);
  const toSelectLabel = useMemo(() => t("endDateSelect"), [t]);

  const idStrutturaConst: string = "idStruttura";
  const idDipartimentoConst: string = "idDipartimento";
  const idUnitaOperativaConst: string = "idUnitaOperativa";
  const daConst: string = "da";
  const aConst: string = "a";

  const [minYear, setMinYear] = useState<string>("");
  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dipSelectName]: null,
    [unitOpSelectName]: null,
    [fromSelectName]: null,
    [toSelectName]: null,
  });

  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupUO = useAppSelector(state => state.unitaOperativeFiltered.lookup);

  const fixedProps = useMemo(() => {
    return {
      [idStrutturaConst]: states[strSelectName],
      [idDipartimentoConst]: states[dipSelectName],
      [idUnitaOperativaConst]: states[unitOpSelectName],
      [daConst]: states[fromSelectName],
      [aConst]: states[toSelectName],
    };
  }, [dipSelectName, fromSelectName, states, strSelectName, toSelectName, unitOpSelectName]);

  useEffect(() => {
    if (fixedProps[idStrutturaConst] && fixedProps[idDipartimentoConst]) {
      dispatch(fetchUnitaOperativeLookup({
        idStruttura: states[strSelectName] as number,
        idDipartimento: states[dipSelectName] as number
      }));
    }
    if (fixedProps[idStrutturaConst]) {
      dispatch(fetchDipartimentiLookup({ idStruttura: states[strSelectName] as number }));
    } else {
      dispatch(fetchStruttureLookup());
    }
  }, [dipSelectName, dispatch, fixedProps, states, strSelectName]);

  /**
   * Data fetch
   */
  useEffect(() => {
    if (fixedProps[idStrutturaConst] && fixedProps[idDipartimentoConst] && fixedProps[idUnitaOperativaConst] && fixedProps[daConst] && fixedProps[aConst]) {
      dispatch(fetchStraordinariBetweenDates({
        idStruttura: fixedProps[idStrutturaConst] as number,
        idDipartimento: fixedProps[idDipartimentoConst] as number,
        idUnitaOperativa: fixedProps[idUnitaOperativaConst] as number,
        da: fixedProps[daConst].toString(),
        a: fixedProps[aConst].toString()
      }));
    }
  }, [dispatch, fixedProps]);

  /**
   * Column creation
   */
  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>([]);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettoCurrentData>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettoCurrentData> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field ? f.field : "",
          removable: f.removable ?? !f.required,
          editable: "never",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render)
          obj.render = f.render;

        if (f.defaultGroupOrder != null) {
          obj.defaultGroupOrder = f.defaultGroupOrder;
        }

        /*+++ lookups */

        /*+++*/
        return obj;
      })
    );
  }, [t, allFieldsState, excludedFieldInTable]);

  // da aggiornare
  const errorBE = useAppSelector((state) => state.presenze.error);
  const validStraordinari = useAppSelector((state) => state.presenze.validStraordinari);
  const statusValidStraordinari = useAppSelector((state) => state.presenze.statusValidStraordinari);

  /**
   * set data on validStraordinari update
   */
  const [data, setData] = useState<Array<Straordinari>>([]);
  useEffect(() => {
    setData(validStraordinari.map(elem => {
      return {
        ...elem,
        nominativo: elem.cognome + " " + elem.nome,
        qualifica: elem.qualificaDescrizioneBreve + ' - ' + elem.qualificaDescrizione,
      }
    }));
  }, [validStraordinari]);

  const handleDateChange = (d: Date | null, name?: string) => {
    let fieldDate: string = "";
    if (d) {
      const month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      const day = (name === fromSelectName) ? "01" : daysInMonth(Number(month), d.getFullYear()).toString();
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";

      name && setStates(prev => { return { ...prev, [name]: fieldDate } });
      if (name === fromSelectName) setMinYear(d.getFullYear().toString())
    }
  };

  useEffect(() => {
    setAllFieldsState(allFields);
  }, []);

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>();
  useEffect(() => {
    const _daConst = fixedProps[daConst];
    const _aConst = fixedProps[aConst];

    const _idStruttura = fixedProps[idStrutturaConst];
    const _idDipartimento = fixedProps[idDipartimentoConst];
    const _idUnitaOperativa = fixedProps[idUnitaOperativaConst];

    const headTitle: string[] = [];
    const headValue: any[] = [];

    // const monthTitleFrom: number = new Date(fixedProps[daConst]).getMonth() + 1;
    // const yearTitleFrom: number = new Date(fixedProps[daConst]).getFullYear();

    // const monthTitleTo: number = new Date(fixedProps[aConst]).getMonth() + 1;
    // const yearTitleTo: number = new Date(fixedProps[aConst]).getFullYear();

    /**
     * init title table
     */
    headTitle.push(t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("startDateTitle"), t("endDateTitle"));
    headValue.push(
      _idStruttura ? lookupStr[_idStruttura] : '',
      _idDipartimento ? lookupDip[_idDipartimento] : '',
      _idUnitaOperativa ? lookupUO[_idUnitaOperativa] : '',
      // dateToString(t, monthTitleFrom, yearTitleFrom),
      // dateToString(t, monthTitleTo, yearTitleTo),
      _daConst ? getDateDDMMYYYY(new Date(_daConst)) : '',
      _aConst ? getDateDDMMYYYY(new Date(_aConst)) : '',
    );

    setExportDataExtra({
      head: {
        title: headTitle,
        value: headValue
      }
    });
  }, [fixedProps, lookupDip, lookupStr, lookupUO, t]);

  useEffect(() => {
    return () => {
      dispatch(resetStrutture());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());
      dispatch(resetPresenze());
    };
  }, [dipSelectName, dispatch, fromSelectName, strSelectName, toSelectName, unitOpSelectName]);

  const _fromSelectName = useMemo(() => states[fromSelectName], [fromSelectName, states]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8} >
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 4, lg: 4 }
                  },
                  {
                    name: dipSelectName,
                    lookup: lookupDip,
                    label: dipSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 4, lg: 4 },
                    callback: resetDipartimentiLookup,
                  },
                  {
                    name: unitOpSelectName,
                    lookup: lookupUO,
                    label: unitOpSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 4, lg: 4 },
                    callback: resetUOLookup,
                  }
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {states[strSelectName] && states[dipSelectName] && states[unitOpSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    label={fromSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, fromSelectName)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[fromSelectName] ? states[fromSelectName] : null}
                    autoOk={true}
                    fullWidth
                  // views={['month', 'year']}
                  />
                </MuiPickersUtilsProvider>
              </Grid>}
            {states[strSelectName] && states[dipSelectName] && states[unitOpSelectName] && _fromSelectName &&
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    minDate={new Date(_fromSelectName)}
                    maxDate={new Date(minYear + "-12-31")}
                    label={toSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, toSelectName)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[toSelectName] ? states[toSelectName] : null}
                    autoOk={true}
                    fullWidth
                  // views={['month']}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          states[fromSelectName] && states[toSelectName] ? (
            <>
              <Switch>
                <Route path={verificaStraordinariPath} exact>
                  <ReadOnlyMaterialTable
                    title={t("overtimeCheckTitle")}
                    columns={columns}
                    data={data}
                    fetchAllValid={fetchStraordinariBetweenDates}
                    statusValid={statusValidStraordinari}
                    errorBE={errorBE}
                    logoUri={logoUri}
                    fixedProps={fixedProps}
                    exportDataExtra={exportDataExtra}
                    isExportLandscape={false}
                    extraOptions={{
                      minBodyHeight: calculateMTableHeight(0, false, true),
                      maxBodyHeight: calculateMTableHeight(0, false, true),
                    }}
                  />
                </Route>
                <Route>
                  <Redirect to={notFoundPath} />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path={verificaStraordinariPath} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {fromSelectLabel}
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={verificaStraordinariPath} /></Route>
            </Switch>
          )}
      </Box>
    </>
  );
};
export default VerificaStraordinariW;
