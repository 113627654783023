import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Box,
  Grid,
  Typography,
  Tooltip,
  Divider,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';

import clsx from 'clsx';

import { useTranslation } from "react-i18next";
import { Pianificazione } from '../../../../../models/Pianificazioni';
import { addDays, getDaysInMonth } from 'date-fns';
import { getDateYYYYMMDD } from '../../../../../utils/utilfunctions';
import { TurniColors } from '../../../../../utils/utildata';


/** CALENDAR */
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hCell: {
      height: 120
    },
    disabled: {
      color: "#cecece"
    },
    bold: {
      fontWeight: "bold"
    },
    error: {
      color: '#f00',
    },
    bgError: {
      backgroundColor: "#900",
      color: "#fff"
    },
    bgErrorDiffMonth: {
      backgroundColor: "#b36767",
      color: "#fff",
    },
    paper: {
      padding: theme.spacing(2, 4, 3),
      marginTop: '2em',
    },
    textLightGray: {
      color: "#ccc",
    },
    turnoMattina: {
      backgroundColor: TurniColors.Mattina,
    },
    turnoPomeriggio: {
      backgroundColor: TurniColors.Pomeriggio,
    },
    turnoNotte: {
      backgroundColor: TurniColors.Notte,
    },
    turnoSmontoNotte: {
      backgroundColor: TurniColors.SmontoNotte,
    },
    turnoRiposo: {
      backgroundColor: TurniColors.Riposo,
    },
    assenza: {
      backgroundColor: TurniColors.Assenza,
    },
    altriTurni: {
      backgroundColor: TurniColors.AltriTurni,
    }
  }),
);

export interface PianificazioniDipendente {
  idAnagraficaSoggetto: number,
  nome: string,
  cognome: string,
  pianificazioni: PianificazioneSingoloGiorno[],
}

export interface PianificazioneSingoloGiorno {
  data: string,
  infoTurno: InfoTurno[],
}

export interface InfoTurno {
  idStruttura: number,
  strutturaNome: string,
  idDipartimento: number,
  dipartimentoNome: string,
  idUnitaOperativa: number,
  unitaOperativaNome: string,
  idQualifica: number,
  qualificaDescrizione: string,
  qualificaDescrizioneBreve: string,
  idTurno?: number,
  turnoDescrizione?: string,
  turnoDescrizioneBreve?: string,
  idTurnoAssenza?: number,
  turnoAssenzaDescrizione?: string,
  turnoAssenzaDescrizioneBreve?: string,
  oreTurno: number;
  minutiTurno: number;
}

interface CalendarProps {
  pianificazioni: Pianificazione[],
  anno: number,
  mese: number, // 1-12
}

const CalendarDipendentiPianificazioni = ({ pianificazioni: pianificazioniInit, anno, mese }: CalendarProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const daysOfTheWeek: string[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const dayOfOne: number = new Date(anno, mese - 1, 1).getDay();

  const dateNow = new Date();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [pianificazioni, setPianificazioni] = useState<Pianificazione[]>([]);
  useEffect(() => {
    setPianificazioni(pianificazioniInit);
  }, [pianificazioniInit])

  const [preparedData, setPreparedData] = useState<PianificazioneSingoloGiorno[]>([]);
  useEffect(() => {
    setPreparedData(prepareData(pianificazioni, anno, mese, dayOfOne));
  }, [anno, dayOfOne, mese, pianificazioni]);

  const numberOfWeeks = useMemo(() => {
    return Math.ceil(preparedData.length / daysOfTheWeek.length)
  }, [daysOfTheWeek.length, preparedData.length]);

  return (
    <>
      <Paper >
        <Box p={1}>
          <Grid container>
            {/* Header: days of the week & total column */}
            <Grid item xs={12} container>
              {daysOfTheWeek.map((dayOfTheWeek, index) => (
                <Grid key={'dayOfWeek' + index} item xs>
                  <Paper>
                    <Typography align="center">
                      {t(dayOfTheWeek).substring(0, 3)}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {
              // calendar table
              new Array(numberOfWeeks)
                .fill(null)
                .map((_, weekNumber) => {
                  // week row
                  return <Grid key={'calendar' + weekNumber} item container xs={12}>
                    {
                      daysOfTheWeek.map((_, indexDayOfTheWeek) => {
                        const dateIndex: number = weekNumber * daysOfTheWeek.length + indexDayOfTheWeek;
                        const currentDate: Date = new Date(preparedData[dateIndex]?.data);
                        const currentDateNumber = currentDate.getDate();


                        // Crea la cella di una giornata dei turni
                        return (
                          <Grid key={'dateCell' + weekNumber.toString() + '-' + indexDayOfTheWeek} item xs >
                            {
                              currentDate.getMonth() === mese - 1 &&
                              <Box
                                border={1}
                                borderRadius={2}
                                borderColor="gray"
                                bgcolor={getDateYYYYMMDD(dateNow) === getDateYYYYMMDD(currentDate) ? '#ccffcc' : undefined}
                                className={classes.hCell}
                              >

                                {/* date title number */}
                                <div>
                                  <Typography className={classes.bold} align="center">
                                    {currentDateNumber}
                                  </Typography>
                                </div>

                                <Divider />

                                { /* Lista turni*/
                                  preparedData && preparedData[dateIndex] && currentDate.getMonth() === mese - 1 &&
                                  preparedData[dateIndex].infoTurno.map((turno, index) => {

                                    let turnoStyle;

                                    if (turno.turnoAssenzaDescrizioneBreve != null) {
                                      turnoStyle = classes.assenza;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('M')) {
                                      turnoStyle = classes.turnoMattina;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('P')) {
                                      turnoStyle = classes.turnoPomeriggio;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('N')) {
                                      turnoStyle = classes.turnoNotte;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('SN')) {
                                      turnoStyle = classes.turnoSmontoNotte;
                                    }
                                    else if (turno.turnoDescrizioneBreve?.startsWith('R')) {
                                      turnoStyle = classes.turnoRiposo;
                                    } else {
                                      turnoStyle = classes.altriTurni;
                                    }

                                    return (
                                      <TooltipTurno
                                        key={'tooltip-pianificazione-' + weekNumber.toString() + '-' + indexDayOfTheWeek + index}
                                        turno={turno}
                                      >
                                        <Grid
                                          container
                                          className={clsx(turnoStyle)}
                                        >
                                          <Grid item xs>
                                            <Typography style={{ textAlign: 'center', fontSize: isMobile ? '0.75em' : '1em' }}>
                                              {
                                                isMobile
                                                  ? (turno.turnoDescrizioneBreve ?? turno.turnoAssenzaDescrizioneBreve)
                                                  : (turno.turnoDescrizione ?? turno.turnoAssenzaDescrizione)
                                              }
                                            </Typography>
                                            <Typography style={{ textAlign: 'center', fontSize: isMobile ? '0.75em' : '1em' }}>
                                              {turno.oreTurno + ':' + (turno.minutiTurno?.toString().padStart(2, '0'))}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </TooltipTurno>
                                    )
                                  })
                                }
                              </Box>
                            }
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                })
            }
          </Grid>
        </Box>
      </Paper>
    </>
  );
}

export default CalendarDipendentiPianificazioni;

interface TooltipTurnoProps {
  children: React.ReactElement,
  turno: InfoTurno,
}

const TooltipTurno = (props: TooltipTurnoProps) => {
  const { turno, children } = props;

  return (
    <Tooltip
      title={<div style={{ fontSize: 13 }}>
        <div style={{ borderBottomWidth: 1, borderBottomColor: '#cecece', borderBottomStyle: 'solid', paddingTop: 5, paddingBottom: 5 }}>
          <span style={{ fontFamily: 'Helvetica' }}>Struttura:</span> <span style={{ fontWeight: 'bold' }}>{turno.strutturaNome}</span><br />
        </div>
        <div style={{ borderBottomWidth: 1, borderBottomColor: '#cecece', borderBottomStyle: 'solid', paddingTop: 5, paddingBottom: 5 }}>
          <span style={{ fontFamily: 'Helvetica' }}>Dipartimento:</span> <span style={{ fontWeight: 'bold' }}>{turno.dipartimentoNome}</span><br />
        </div>
        <div style={{ borderBottomWidth: 1, borderBottomColor: '#cecece', borderBottomStyle: 'solid', paddingTop: 5, paddingBottom: 5 }}>
          <span style={{ fontFamily: 'Helvetica' }}>Unita Operativa:</span> <span style={{ fontWeight: 'bold' }}>{turno.unitaOperativaNome}</span><br />
        </div>
        <div style={{ borderBottomWidth: 1, borderBottomColor: '#cecece', borderBottomStyle: 'solid', paddingTop: 5, paddingBottom: 5 }}>
          <span style={{ fontFamily: 'Helvetica' }}>Qualifica:</span> <span style={{ fontWeight: 'bold' }}>{turno.qualificaDescrizione}</span><br />
        </div>
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          {
            turno.turnoDescrizione ?
              <>
                <span style={{ fontFamily: 'Helvetica' }}>Turno:</span> <span style={{ fontWeight: 'bold' }}>{turno.turnoDescrizione}</span><br />
              </>
              :
              <>
                <span style={{ fontFamily: 'Helvetica' }}>Assenza:</span> <span style={{ fontWeight: 'bold' }}>{turno.turnoAssenzaDescrizione}</span><br />
              </>
          }
        </div>
      </div>}
    >
      {children}
    </Tooltip>
  )
}

function prepareData(pianificazione: Pianificazione[], anno: number, mese: number, offset: number): PianificazioneSingoloGiorno[] {
  const retval: PianificazioneSingoloGiorno[] = [];
  const temp: PianificazioneSingoloGiorno[] = [];

  const dataRiferimento = new Date(anno, mese - 1, 1);

  const maxDays = getDaysInMonth(dataRiferimento);

  for (let i = 0; i < offset; i++) {
    temp.push({
      data: getDateYYYYMMDD(addDays(new Date(anno, mese - 1, 1), -(offset - i))),
      infoTurno: [],
    })
  }

  for (let i = 0; i < maxDays; i++) {
    const _dataRiferimento = getDateYYYYMMDD(new Date(anno, mese - 1, i + 1));
    temp.push({
      data: _dataRiferimento,
      infoTurno: [],
    });

    const presenzeFiltered = pianificazione.filter(elem => {
      return elem.pianificazioneData.startsWith(_dataRiferimento);
    });

    presenzeFiltered.forEach(elem => {
      const infoTurno: InfoTurno = {
        idStruttura: elem.idStruttura,
        idDipartimento: elem.idDipartimento,
        idUnitaOperativa: elem.idUnitaOperativa,
        idQualifica: elem.idQualifica,
        idTurno: elem.idTurno,
        idTurnoAssenza: elem.idTurnoAssenza,
        oreTurno: elem.oreTurno,
        minutiTurno: elem.minutiTurno,
        strutturaNome: elem.strutturaNome,
        dipartimentoNome: elem.dipartimentoNome,
        unitaOperativaNome: elem.unitaOperativaNome,
        qualificaDescrizione: elem.qualificaDescrizione,
        qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
        turnoDescrizione: elem.turnoDescrizione,
        turnoDescrizioneBreve: elem.turnoDescrizioneBreve,
        turnoAssenzaDescrizione: elem.turnoAssenzaDescrizione,
        turnoAssenzaDescrizioneBreve: elem.turnoAssenzaDescrizioneBreve,
      };

      temp[temp.length - 1]?.infoTurno.push({ ...infoTurno });
    });
  }

  return retval.concat(temp);
}