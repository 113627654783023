import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface StatusIconIndicatorProps {
  color: string
}

const StatusIconIndicator = (props: StatusIconIndicatorProps) => {
  const { color } = props;

  return <FiberManualRecordIcon fontSize='medium' htmlColor={color} stroke='#000' />
}

export default StatusIconIndicator