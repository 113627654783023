export enum ExportType {
  PDF,
  CSV,
  PDF_CSV
}

export enum WeekDays {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
}

export const WeekList: readonly string[] = [
  "all",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
]

export const MonthsList: readonly string[] = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
]

export const TurniColors = {
  Mattina: '#A8E6CF',
  Pomeriggio: '#FFD54F',
  Notte: '#599DEB',
  SmontoNotte: '#B0BEC5',
  Riposo: '#CE93D8',
  Assenza: '#CFD8DC',
  AltriTurni: '#FFB74D'
} as const;
export type TurniColorsKeys = keyof typeof TurniColors;

export const Colors = {
  Bianco: '#FFFFFF',
  Rosso: '#FF0000',
  Verde: '#00FF00',
  Giallo: '#FFFF00',
  Arancione: '#FFAA00',
} as const                // this will make object properties immutable
export type ColorsKeys = keyof typeof Colors

export const StatoPianificazioneTranslation = {
  NUOVO: 'nuovoLabel',
  INLAVORAZIONE: 'inLavorazioneLabel',
  DAAPPROVARE: 'daApprovareLabel',
  APPROVATO: 'approvatoLabel',
} as const
export type StatoPianificazioneTranslationKeys = keyof typeof StatoPianificazioneTranslation

export const StatoPianificazioneMap = {
  NUOVO: 'NUOVO',
  INLAVORAZIONE: 'INLAVORAZIONE',
  DAAPPROVARE: 'DAAPPROVARE',
  APPROVATO: 'APPROVATO',
} as const
export type StatoPianificazioneMapKeys = keyof typeof StatoPianificazioneMap

export const CombinazioneTurniLookup = {
  'M6': 'H6 (Solo Mattina)',
  'P6': 'H6 (Solo Pomeriggio)',
  'N6': 'H6 (Solo Notte)',
  'M-P': 'H12 (Mattina - Pomeriggio)',
  'M-N': 'H12 (Mattina - Notte)',
  'P-N': 'H12 (Pomeriggio - Notte)',
  'M-P-N': 'H24 (Mattina - Pomeriggio - Notte)',
  'LA': 'Lunga Assenza',
} as const
export type CombinazioneTurniLookupKeys = keyof typeof CombinazioneTurniLookup

export const ElencoTurniFissiLookup = {
  M: 'Mattina',
  P: 'Pomeriggio',
  N: 'Notte',
} as const
export type ElencoTurniFissiLookupKeys = keyof typeof ElencoTurniFissiLookup

// questi valori vengono passati al useTranslation
export const TipoPartTimeLookup = {
  VP: 'verticalParam',
  OP: 'horizontalParam',
  MP: 'mixedParam',
} as const
export type TipoPartTimeLookupKeys = keyof typeof TipoPartTimeLookup

export const TipoContrattoLookup = {
  'A.P': 'A.P (Apprendistato)',
  'T.D.': 'T.D. (Tempo Determinato)',
  'T.I.': 'T.I. (Tempo Indeterminato)',
  'COMANDO': 'COMANDO (Comando)',
  'T.I.M.U.': 'T.I.M.U. (Tempo Indeterminato M.U.)',
  'T.I. INRCA': 'T.I. INRCA (Tempo Indeterminato INRCA)',
  'T.D.D.P.': 'T.D.D.P. (Tempo Determinato D.P.)',
  'T.D.M.U.': 'T.D.M.U. (Tempo Determinato M.U.)',
  'T.EC': 'T.EC (Tirocinio Extracurriculare)',
} as const
export type TipoContrattoLookupKeys = keyof typeof TipoContrattoLookup

export enum VersoTimbratura {
  USCITA,
  ENTRATA
}

export const VersoTimbraturaLookup = {
  0: 'Uscita',
  1: 'Entrata',
} as const
export type VersoTimbraturaKeys = keyof typeof VersoTimbraturaLookup

export const SessoLookup = {
  F: 'FEMMINA',
  M: 'MASCHIO',
} as const
export type SessoLookupKeys = keyof typeof SessoLookup

export const Profili = {
  AMMINISTRATORE: 'AMMINISTRATORE',
  SUPER_AMMINISTRATORE: 'SUPER AMMINISTRATORE',
  DIPENDENTE: 'DIPENDENTE',
  CAPO_SALA: 'CAPO SALA',
} as const
export type ProfiliKeys = keyof typeof Profili


export const DimensioneFogli = {
  a4: {
    portrait: {
      width: 210,
      height: 297
    },
    landscape: {
      width: 297,
      height: 210
    }
  },
  a3: {
    portrait: {
      width: 297,
      height: 420
    },
    landscape: {
      width: 420,
      height: 297
    }
  }
} as const;
export type DimensioneFogliKeys = keyof typeof DimensioneFogli

Object.preventExtensions(Profili);
Object.preventExtensions(SessoLookup);
Object.preventExtensions(TipoPartTimeLookup);
Object.preventExtensions(StatoPianificazioneMap);
Object.preventExtensions(StatoPianificazioneTranslation);
Object.preventExtensions(TurniColors);
Object.preventExtensions(Colors);
Object.preventExtensions(WeekList);
Object.preventExtensions(MonthsList);
Object.preventExtensions(CombinazioneTurniLookup);
Object.preventExtensions(TipoContrattoLookup);
Object.preventExtensions(VersoTimbraturaLookup);
Object.preventExtensions(DimensioneFogli);